import React, { useEffect } from 'react';
import { graphql, PageProps, Script } from 'gatsby';
import Section from '@components/Sections/Section';
import useSaveUtmParamsToLocalStorage from '@src/hooks/useSaveUtmParamsToLocalStorage';
import { HeaderFragmentWithVariant } from '@components/Layout/NavigationBar/interfaces';
import { PageQuery, PageQueryVariables } from '../../graphql-types';
import PageWrapper from './shared/PageWrapper';
import { analyticsSnippet } from './analytics/analytics';
import trackPage from './analytics/trackPage';

const Page = ({
  data,
  pageContext,
}: PageProps<PageQuery, PageQueryVariables>) => {
  useSaveUtmParamsToLocalStorage();

  useEffect(() => {
    trackPage({ pageSlug: data.page?.slug?.localized });
  }, [data.page?.slug?.localized]);

  return (
    <PageWrapper
      locale={pageContext.locale}
      navigationBar={data?.page?.navigationBar as HeaderFragmentWithVariant}
      transparentNavigationBar={data?.page?.transparentNavigationBar}
      metadata={data?.page?.metadata}
      announcementId={data?.page?.navigationBar?.announcement?._id}
      footerBar={data?.sanityFooterBar}
    >
      {data.page.content.map((sectionContent) => (
        <Section key={sectionContent?._key} content={sectionContent} />
      ))}
    </PageWrapper>
  );
};

export const Head = () => (
  <>
    <Script
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: analyticsSnippet,
      }}
    />
    <Script
      async
      src="https://tag.clearbitscripts.com/v1/pk_54aa5d556dad4b9548cc7054eba8b2ca/tags.js"
      referrerPolicy="strict-origin-when-cross-origin"
    />
  </>
);

export const query = graphql`
  query Page($id: String!, $locale: String!, $footerBarId: String!) {
    sanityFooterBar(_id: { eq: $footerBarId }) {
      ...FooterBar
    }
    page: sanityPage(_id: { eq: $id }) {
      _id
      _key
      slug {
        localized(locale: $locale)
      }
      content {
        ...ContentSections
      }
      metadata {
        ...SEO
      }
      tracking {
        ...tracking
      }
      navigationBar {
        ...Header
      }
      transparentNavigationBar
      redirects
    }
  }
`;

// ts-prune-ignore-next
export default Page;
